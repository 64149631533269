import React from "react";
import { Alternative as AlternativeType } from "app-types/alternative";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import PlainHeader from "components/ui/base/PlainHeader";
import AlternativeTable from "components/ui/extended/AlternativeTable";
import Customers from "components/ui/extended/Customers";
import Register from "components/ui/extended/RegisterForCustomerService";
import QuestionsAndAnswers from "components/ui/base/QuestionsAndAnswers";
import FeatureGrid from "components/ui/base/FeatureGrid";
import CTASection from "components/ui/base/CTASection";
import { useTranslation } from "react-i18next";
import TestimonialWithImageFrancesca from "components/ui/extended/TestimonialWithImageFrancesca";

const Alternative = ({ pageContext, location }: any) => {
  const alternative: AlternativeType = pageContext.alternative;
  const { t } = useTranslation();

  const metaTitle = t("templates.alternative.metaTitle", {
    alternative: alternative.name,
    year: new Date().getFullYear(),
  });

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={metaTitle}
        description={alternative.metaDescription}
        image={{
          imageData:
            alternative.metaImage?.localFile.childImageSharp.gatsbyImageData,
          alt: metaTitle,
        }}
      />

      <PlainHeader
        title={t("templates.alternative.title", {
          alternative: alternative.name,
        })}
        description={t("templates.alternative.description", {
          alternative: alternative.name,
        })}
        ctaRegister={{
          show: true,
        }}
      />

      <TestimonialWithImageFrancesca />

      <AlternativeTable
        alternative={alternative.name}
        rows={alternative.alternativeTable}
      />

      <FeatureGrid
        alternativeColors
        title={t("templates.alternative.featureGrid.title")}
        button={{
          text: t("templates.alternative.featureGrid.buttonText"),
          path: "/features/",
        }}
        sections={alternative.exclusiveFeatures.map((feature) => {
          return {
            title: feature.title,
            description: feature.description,
            links: [
              {
                text: `Explore ${feature.title}`,
                path: `/features/${feature.slug}/`,
              },
            ],
          };
        })}
      />

      <CTASection
        title={alternative.ctaText}
        button={{
          text: alternative.ctaButtonText,
          path: alternative.ctaButtonLink,
        }}
        image={{
          alt: alternative.ctaImage.alt,
          data: alternative.ctaImage.localFile.childImageSharp.gatsbyImageData,
        }}
      />

      <Customers />

      <Register />

      <QuestionsAndAnswers
        title={t("templates.alternative.faq.title", {
          alternative: alternative.name,
        })}
        description={t("templates.alternative.faq.description", {
          alternative: alternative.name,
        })}
        elements={alternative.faqElements}
      />
    </GlobalWrapper>
  );
};

export default Alternative;
